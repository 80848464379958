import React from "react";
import styled, { withTheme } from "styled-components";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faPowerOff, faVolumeOff } from "@fortawesome/fontawesome-free-solid";

const Container = styled.div`
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 12px;
  flex: 1;
  padding-bottom: 0.5em;

  @media all and (max-width: 600px) {
    gap: 0px;
    justify-content: flex-start;
    padding-bottom: 0em;
  }

  font-size: 14px;
`;

const AvatarConfigurations = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  --border-radius: 20px;

  border-radius: var(--border-radius) var(--border-radius) var(--border-radius) var(--border-radius);

  & > :nth-child(1) {
    background-color: ${({ theme }) => theme.lightGray};
    border-radius: var(--border-radius) 0px 0px var(--border-radius);
    padding-left: 24px;
  }

  & > :nth-child(n) {
    background-color: ${({ theme }) => theme.lightGray};
  }

  & > :nth-last-child(1) {
    background-color: ${({ theme }) => theme.lightGray};
    border-radius:  0px var(--border-radius) var(--border-radius) 0px;
    padding-right: 24px;
  }

  & > :only-child {
    border-radius: var(--border-radius) var(--border-radius) var(--border-radius) var(--border-radius);
  }

  @media all and (max-width: 600px) {
    --border-radius: 10px;
    gap: 8px;
  }
`;

const AvatarConfiguration = styled.button`
  --border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex: 1;
  box-sizing: border-box;
  margin: 0;

  & > p {
    margin: 0 !important;
  }

  background-color: transparent;
  border: none;
  padding: 8px 16px !important;
  color: ${({ theme }) => theme.darkGray};

  transition: all 120ms ease;

  @media all and (min-width: 600px) {
    &:hover {
      cursor: pointer;
      opacity: 0.7;

      /* Achei giro mas depois iria ser estúpido
      transform: scale(1.1);
      margin: 0 16px;
      border-radius: var(--border-radius) var(--border-radius) var(--border-radius) var(--border-radius);
      */
    }
  }


  @media all and (max-width: 600px) {
    --border-radius: 10px;
    border-radius: var(--border-radius) var(--border-radius) var(--border-radius) var(--border-radius) !important;

    &:hover {
      opacity: 0.7;
      transform: none;
      transition: opacity 120ms ease;
    }
  }
`;

const AvatarScreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AvatarCommands = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 4px;
  padding: 0px 8px;

  overflow-y: auto;

  @media all and (max-width: 600px) {
    &.question-chosen {
      display: none;
    }
  }

  @media all and (max-width: 600px) {
    display: none;
  }
`;

const AvatarCommand = styled.button`
  border: 1px solid ${({ theme }) => theme.darkGray};
  border-radius: 8px;
  padding: 12px 16px !important;
  width: 100%;
  background: transparent;
  text-align: left;

  @media all and (max-width: 600px) {
    padding: 8px !important;
  }

  &.selected {
    font-weight: bold;
    border-color: ${({ theme }) => theme.primaryColor};
  }

  @media all and (max-width: 600px) {
    border-width: 1.5px;
  }

  transition: all 120ms ease;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    transform: scale(1.025);
  }
`;

const ResetContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;

const ResetButton = styled(AvatarConfiguration)`
  --border-radius: 20px;
  border-radius: var(--border-radius) var(--border-radius) var(--border-radius) var(--border-radius);
  background-color: ${({ theme }) => theme.lightGray};
  flex: 0 0 0;
  min-width: 50%;
`;

class VideoContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasAlreadyChosen: false,
      actions: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.actions !== this.props.actions) {
      let actions = this.props.actions ?
      this.props.actions.match(/<action>([a-zA-Z]|[à-ü]|[À-Ü]|\?|\.|,|;|\s)+<\/action>/g) :
      [];

      this.setState({
        actions: actions.sort(() => Math.random() - Math.random()).slice(0, 3)
      })
    }
  }

  choseOption = (command) => {
    this.setState({ hasAlreadyChosen: true }, () => {
      this.props.appendMessage({
        text: command,
        outbound: true,
        sentAt: new Date()
      });
    });
  }

  // TODO: a implementar lógica
  render() {
    const { botIsActive } = this.props;

    const buttonStyle = {
      padding: 0,
      height: "fit-content",
      padding: "2.4px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      backgroundColor: this.props.theme.green,
      color: this.props.theme.white
    };

    return (
      <Container>
        {botIsActive && (
          <AvatarConfigurations>
            <AvatarConfiguration
              onClick={this.props.toggleAssistant}
              >
              <FontAwesomeIcon
                icon={faPowerOff}
                style={{...buttonStyle, backgroundColor: !this.props.isAssistantVideoActive ? this.props.theme.green : this.props.theme.errorColor}}
              />
              <p>{ this.props.isAssistantVideoActive ? "Desligar" : "Ligar" } avatar</p>
            </AvatarConfiguration>
            {/* <AvatarConfiguration>
              <FontAwesomeIcon
                icon={faVolumeOff}
                style={buttonStyle}
              />
              <p>Desligar voz</p>
            </AvatarConfiguration> */}
          </AvatarConfigurations>
        )}
        <AvatarScreenContainer>
          {this.props.children}
        </AvatarScreenContainer>
        {botIsActive && this.props.hasTermsAccepted && (
          <AvatarCommands
            className={this.state.hasAlreadyChosen && "question-chosen"}
          >
            {!this.state.hasAlreadyChosen && (
              <React.Fragment>
                {!!this.state.actions && this.state.actions
                      .map(e => e.replace("<action>", "").replace("</action>", ""))
                      .map(action => (<AvatarCommand key={action} onClick={() => {this.choseOption(action)}}>{action}</AvatarCommand>))}
              </React.Fragment>
            )}
          </AvatarCommands>
        )}
        { this.props.hasTermsAccepted && <ResetContainer>
          <ResetButton onClick={() => {
              (this.props.resetChat ? this.props.resetChat : () => {})();
            }}
            >
              Reiniciar Conversa
          </ResetButton>
        </ResetContainer>}
      </Container>
    );
  }
}

export default withTheme(VideoContainer);
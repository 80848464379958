import React, { Component } from "react";
import posed from "react-pose";
import { spring } from "popmotion";
import styled from "styled-components";

import { AppContext } from "./index.js";
import { ContextMenuTrigger } from "react-contextmenu";

const Img = styled(
  posed.img({
    open: {
      scale: 0,
      opacity: 0,
      transition: props => spring({ ...props, stiffness: 250, mass: 0.125, damping: 15 })
    },
    closed: {
      scale: 1,
      opacity: 1,
      transition: props => spring({ ...props, stiffness: 250, mass: 0.125, damping: 15 })
    },
    hovered: {
      scale: 0.85,
      transition: props => spring({ ...props, stiffness: 250, mass: 0.125, damping: 15 })
    }
  })
)`
  z-index: 20000000000000000;
  position: absolute;
  bottom: 6px;
  right: 6px;
  width: 74px;
  height: 74px;
  border-radius: 80px;
  text-indent: -9999px;
  background: ${({ theme }) => theme.lightGray};
  border: 3px solid white;
  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 8px -2px rgba(0,0,0,0.333);
    border: 0;
  }
  @media only screen and (hover: none) and (pointer: coarse) and (max-width: 600px) {
    &.open {
      width: 58px;
      height: 58px;
    }
  }
`;

export default props => {
  return (
    <AppContext.Consumer>
      {({ pose, openChatModalClick, onMouseHover }) => (
        <Img
          {...props}
          onClick={openChatModalClick}
          onMouseEnter={onMouseHover && onMouseHover.bind(this, true)}
          onMouseLeave={onMouseHover && onMouseHover.bind(this, false)}
          className={pose == "open" ? "aida-main-image-trigger open" : "aida-main-image-trigger closed"}
        />
      )}
    </AppContext.Consumer>
  );
};
